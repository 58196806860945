<template>
  <v-form v-bind="this.$attrs">
    <v-row>
      <v-col class="d-flex justify-space-between" cols="12" xs="12" sm="12" md="10">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="4">
            <v-text-field v-model="code" placeholder="Mã hồ sơ" clearable flat outlined dense hide-details />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4">
            <v-select
              :items="departments"
              item-text="title"
              item-value="value"
              v-model="department"
              placeholder="Cơ sở"
              :disabled="disabledDepartmentFilter"
              clearable
              outlined
              dense
              hide-details
            />
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="4">
            <v-text-field v-model="userPhone" placeholder="SĐT tài khoản" clearable flat outlined dense hide-details />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="2">
        <v-btn color="admin" height="40px" width="100%" @click="onFilterChanged" outlined>
          Tìm kiếm
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col class="d-flex justify-space-between" cols="12" xs="12" sm="12" md="10">
        <v-row class="my-0">
          <v-col class="pb-0" cols="12" xs="12" sm="12" md="4">
            <v-text-field v-model="name" v-if="advancedFilter" placeholder="Học sinh" clearable flat outlined dense />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="2" class="py-6">
        <v-btn color="admin" height="40px" width="100%" @click="advancedFilter = !advancedFilter" text>
          Nâng cao <v-icon v-if="!advancedFilter">mdi-chevron-down</v-icon><v-icon v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  computed: {
    ...mapGetters('auth', ['user'])
  },
  created() {
    if (this.user.department === 'both') {
      this.disabledDepartmentFilter = false
      this.department = ''
    } else this.department = this.user.department
  },
  data() {
    return {
      disabledDepartmentFilter: true,
      advancedFilter: false,
      code: '',
      department: '',
      name: '',
      userPhone: '',
      departments: [
        {title: 'Cơ sở A', value: 'Cơ sở A'},
        {title: 'Cơ sở 1', value: 'Cơ sở 1'}
      ]
    }
  },
  methods: {
    ...mapActions('user', ['fetchAdminUsers']),
    onFilterChanged() {
      let query = {
        code: this.code,
        name: this.name,
        parentUsername: this.userPhone
      }
      if (!this.department || this.department === 'both') query.department_in = ['unset', 'Cơ sở 1', 'Cơ sở A']
      else query.department_in = ['unset', this.department]
      this.$emit('onFilterChanged', query)
    }
  }
}
</script>
